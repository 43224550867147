



















































































import { Component, Watch, Vue } from 'vue-property-decorator';
import { api } from '@/api';
import ip from 'ip';
import axios from 'axios';
import { apiUrl } from '@/env';
import { ValidateIPaddress, ValidateCIDRaddress } from '@/tools';


@Component
export default class FormInfinet extends Vue {
  // file
  private fileName: string = '';

  // form data
  private chosenDevice: string = 'Infinet';
  private CPE_LIST: string[] = [];
  private BS_LIST: string[] = [];
  private bsip: string = '';

  private login: string = '';
  private password: string = '';
  
  private bsipErrors: string = '';
  private bsipOk: boolean = true;
  private bsIsConfiguring: boolean = false;
  // private ipRange: boolean = true;

  private formState: string = 'info';
  private formMainMessage: string = 'производится подключение и настройка...';
  private formSubmitted: boolean = false;

  private bsLogin: string = '';
  private bsPassword: string = '';

  private newPwd: string = '';

  private submitted: boolean = false;
  private doNotchangeIpInKrus: boolean = false;
  private submitMsg: string = 'отправлено';


  private ipRangeErrors: string = '';
  // buttons
  private showReset: boolean = false;

  // forms
  private form: any = null;

  // @Watch('passwords')
  // public SelectIpCidrChanged(newVal: string) {
  //   this.clearIpRange();
  //   if (this.ipRange) {
  //     this.setIpRange();
  //   }
  // }

  public mounted() {
    this.form = this.$refs.bsform;
  }

  // form checkers
  private theFieldIsVlan(v: number) {
    if (v && v >= 0 && v < 4096) {
      return true;
    }
    return 'Должен быть номер от 0-4095';
  }

  private theFieldIsRequired(v: string) {
    if (v && v.length > 0) {
      return true;
    }
    return 'Это обязательное поле';
  }

  // fields rules
  private theFieldIsIp(v: string) {
    if (ValidateIPaddress(v)) {
      return true;
    }
    return 'Неверный формат ip адреса';
  }

  private theFieldIsCIDR(v: string) {
    if (ValidateCIDRaddress(v)) {
      return true;
    }
    return 'Неверный формат! попробуйте так: x.x.x.x/xx';
  }

  private onPickFile() {
    const fileInput = this.$refs.fileInput as HTMLInputElement;
    fileInput.click();
    // this.$refs.fileInput.click()
  }

  private onFilePicked(event) {
    this.BS_LIST = []
    const files = event.target.files
    console.log(files)
    if (files[0] !== undefined) {
      this.fileName = files[0].name
    }
    // console.log(files[0]);

    // const lines = files[0].trim().split(/\n/g);
    // const data = fs.readFileSync(files[0], 'utf8');

    this.parseFiles(files)
    // for (let ip of data) {
    //     console.log("ip ", ip);
    // }
  }

  private parseFiles(files: FileList): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const promises: Promise<string>[] = [];

      // перебираем каждый файл и создаем Promise для чтения его содержимого
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        promises.push(this.readFile(file));
      }

      // ждем, пока все Promises завершатся, затем возвращаем массив строк
      Promise.all(promises)
        .then((contents) => {
          const lines: string[] = [];
          for (let i = 0; i < contents.length; i++) {
            const content = contents[i];
            lines.push(...content.split('\n'));
          }
          for (let item of lines) {
            item = item.replace(/\s+/g, '');
            item = item.replace(/\r?\n|\r/g, '');
            if (ValidateIPaddress(item)) {
              this.BS_LIST.push(item)
            }
          }
          resolve(lines);
        })
        .catch((error) => reject(error));
    });
  }

  private readFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const content = event.target?.result as string;
        resolve(content);
      };
      reader.onerror = (event) => {
        reject(event);
      };
      reader.readAsText(file);
    });
  }
  
  private checkIP() {
    // console.log('token ', this.$store.getters.token);
    // this.bsipOk = this.ValidateIPaddress(this.bsip);
    this.bsipErrors = '';
    if (!ValidateIPaddress(this.bsip)) {
      this.bsipErrors = 'Формат ip адреса: x.x.x.x';
      return;
    }

    axios.put(`${apiUrl}/api/v1/devices/ping/${this.bsip}`, this.authheader)
    .then((response) => {
      // console.log(response);
      if (!response.data.ping) {
        this.bsipErrors = response.data.ip + ': адрес не пингуется с сервера!';
        this.bsipOk = false;
      } else {
        this.bsipOk = true;
      }
     })
     .catch((error) => {
      this.bsipErrors = 'Не могу пингануть. Ошибка:' + error;
      console.log(error);
      this.bsipOk = false;
     });
  }

  private submit() {

    if (this.form.validate() && this.bsipOk) {
      if (this.formSubmitted) {
          this.formState = 'info';
          this.formMainMessage = 'Задание уже отправлено!';
          return;
      }
      this.formState = 'info';
      this.formMainMessage = 'производится подключение и настройка...';
      this.formSubmitted = true;

      for (const bsip of this.BS_LIST) {
        console.log("getting ip list from ",bsip)
        this.submitToBs(bsip);
      }
    }
  }

  private reset_forms() {
    this.formState = 'info';
    this.CPE_LIST = [];
    this.form.reset();
    this.formSubmitted = false;
    this.showReset = false;
  }

  private sendTask(bsip,login,password,cpe_list) { // send task to queue
    const data = {
      name: 'pwd_change',
      devicetype : this.chosenDevice,
      iplist : cpe_list,
      bsip : bsip,
      bslogin : login,
      bspwd : password,
      login: this.login,
      pwd: this.password,
      commands: ['sys pass '+this.newPwd],
    };
    axios.post(`${apiUrl}/api/v1/devices/jobs/multi/exec_commands`, data, this.authheader)
    .then((response) => {
      this.showReset = true;
      console.log(response);
      if ('data' in response) {
        this.formState = 'success';
        this.formMainMessage = response.data.msg;
        return;
        }
      this.formMainMessage = 'Сообщение не получено. Похоже что-то пошло не так :(';
      this.formState = 'warning';
      })
     .catch((error) => {
      this.showReset = true;
      this.formState = 'error';
      if ('response' in error) {
        // this.formSubmitted = false;
        const r = error.response;
        if ('data' in r) {
            this.formMainMessage = r.data.detail;
            return;
         }
        this.formMainMessage = `Ошибка ${r.status}: ${r.statusText}`;
        return;
      }
      this.formMainMessage = 'Ошибка:' + error;
     });

  }

  private submitToBs(bsip) { // get ip-list for all cpe of the bs
    const data = {
      device : this.chosenDevice,
      ip : bsip,
      login : this.bsLogin,
      password : this.bsPassword,
    };
    console.log('data:', data);

    axios.post(`${apiUrl}/api/v1/devices/sisyphus/bs/all_cpe`, data, this.authheader)
    .then((response) => {
      // this.formSubmitted = false;
      console.log(response);
      if ('data' in response) {
        const len = response.data.cpe_list.length;
        const cpe_list = response.data.cpe_list;
        const login = response.data.login;
        const password = response.data.password;
        if (len > 0) {
          this.formState = 'success';
          this.formMainMessage = 'найдено CPE: ' + len;
          this.sendTask(bsip,login,password,cpe_list);
          return;
        }
        this.formMainMessage = 'Похоже к сектору '+bsip+' не подключено ни одно устройство';
        this.formState = 'warning';
      }
     })
     .catch((error) => {
      this.showReset = true;
      // this.formSubmitted = false;
      this.formState = 'error';
      if ('response' in error) {
        // this.formSubmitted = false;
        const r = error.response;
        if ('data' in r) {
            this.formMainMessage = r.data.detail;
            return;
         }
        this.formMainMessage = `Ошибка ${r.status}: ${r.statusText}`;
        return;
      }
      this.formMainMessage = 'Ошибка:' + error;
     });
    }

  get authheader() {
    return {
      headers: {
        Authorization : `Bearer ${this.$store.getters.token}`,
      },
  };
  }

}
