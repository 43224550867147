export function ValidateIPaddress(ipaddress: string): boolean {
return (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress));
}


export function ValidateCIDRaddress(ipaddress: string): boolean {
return (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([1-3][0-9])$/.test(ipaddress));
}


export function theFieldIsIp(v: string) {
  if (ValidateIPaddress(v)) {
      return true;
  }
  return 'Неверный формат ip адреса';
}

export function theFieldIsCIDR(v: string) {
  if (ValidateCIDRaddress(v)) {
      return true;
  }
  return 'Неверный формат! попробуйте так: x.x.x.x/xx';
}
