


























































































































import { Component, Watch, Vue } from 'vue-property-decorator';
import { api } from '@/api';
// import { Store } from 'vuex';
import axios from 'axios';
import { apiUrl } from '@/env';
import FormInfinet from '@/components/forms/sisyphus/infinet.vue';
import FormUbiquiti from '@/components/forms/sisyphus/ubiquiti.vue';
import FormCambium from '@/components/forms/sisyphus/cambium.vue';
import FormMedusa from '@/components/forms/sisyphus/medusa.vue';

import TableSisyph from '@/components/tables/sisyph.vue';

import {
    commitAddNotification,
    commitRemoveNotification,
} from '@/store/main/mutations';
// import { convertObjectsToCSV,forceFileDownload } from '@/tools';
// import { dispatchPingIp } from '@/store/main/actions';

// import JsonCSV from 'vue-json-csv';

@Component({
    components: {
        FormInfinet, FormUbiquiti, FormCambium, FormMedusa, TableSisyph,
    },
})
export default class ToolSisyph extends Vue {
  // table
  public results_data: any = null;
  public pagination: any = {sortBy: 'id', descending: true, rowsPerPage: 10};
  public search: string = '';
  public tableHeaders: any =  [
          {
            text: 'id',
            align: 'hidden-xs-only',
            filterable: false,
            value: 'id',
          },
          { text: '', filterable: false, value: 'done' },
          { text: 'Тип', value: 'devicetype' },
          { text: 'ip шлюза', value: 'newgw_cidr' },
          { text: 'Статус', value: 'status' },
        ];
  public tableData: any = [];
  public tableLoading: boolean = false;

  private dialog: boolean = false; // show add-job dialog
  private dialogResults: boolean = false; // show results dialog
  // forms
  private chosenDevice: string = '';
  private devices: string[] = ['Infinet', 'Ubiquiti', 'Cambium', 'Medusa'];



  public mounted() {
    // this.bsform = this.$refs.bsform;
    this.getTableData();

    setInterval(() => this.getTableData(), 5000);
  }

  private  forceFileDownload(data) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'results.csv'); // or any other extension
      document.body.appendChild(link);
      link.click();
    }

  private convertObjectsToCSV(args) {
        let result, ctr, keys, columnDelimiter, lineDelimiter, data;

        data = args.data || null;
        if (data == null || !data.length) {
            return null;
        }

        columnDelimiter = args.columnDelimiter || ',';
        lineDelimiter = args.lineDelimiter || '\n';

        keys = Object.keys(data[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        // data.forEach(function(item) {
        data.forEach((item) => {
            ctr = 0;
            // keys.forEach(function(key) {
            keys.forEach((key) => {
                if (ctr > 0) {
                  result += columnDelimiter;
                }
                // if (item[key].indexOf('\n') > -1) {
                //   item[key] += '\r\n';
                // }
                result += `"${item[key]}"`;
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

  // opens dialog window with results-table
  private showResultsTable(data) {
    this.results_data = data;
    this.dialogResults = true;
  }

  // gets results with specific output for task-id from main table
  private getJob(output, id) {
    const loadingNotification = { content: 'Выгружаем данные...', showProgress: true };
    commitAddNotification(this.$store, loadingNotification);

    axios.get(`${apiUrl}/api/v1/devices/sisyphus/jobs/${id}`, this.authheader)
    .then((response) => {
      commitRemoveNotification(this.$store, loadingNotification);
      // console.log(response);
      // this.csv_data = response.data;
      if (response.data.length === 0) {
        commitRemoveNotification(this.$store, loadingNotification);
        commitAddNotification(this.$store, { content: 'нет данных', color: 'info' });
        return;
      }

      if (output === 'csv') {
        const csv = this.convertObjectsToCSV(response);
        if (csv != null) {
          this.forceFileDownload(csv);
        }
        return;
      }
      if (output === 'table') {
        this.showResultsTable(response.data);
        return;
      }

      })
     .catch((error) => {
      if ('response' in error) {
        // this.bsFormsubmitted = false;
        const r = error.response;
        console.log(r);
        return;
      }
      console.log(error);
     });
  }

  private getTableData() {
    this.tableLoading = true
    if (!this.$store.getters.isLoggedIn) { return; }
    // send task to backend queue

    const d = {
      params: {
        name: 'sisyphus',
        limit: 100
      },
      headers: {
        Authorization : `Bearer ${this.$store.getters.token}`,
      },      
    }

    axios.get(`${apiUrl}/api/v1/devices/sisyphus/jobs`, d)
    .then((response) => {
      // console.log(response);
      this.tableData = response.data;
      })
     .catch((error) => {
      // this.bsFormState = 'error';
      if ('response' in error) {
        // this.bsFormsubmitted = false;
        const r = error.response;
        console.log(r);
        return;
      }
      console.log(error);
     });
     this.tableLoading = false
  }

  get authheader() {
    return {
      headers: {
        Authorization : `Bearer ${this.$store.getters.token}`,
      },
  };
  }

}
