














































































































import axios from 'axios';
import { apiUrl } from '@/env';
import { Component, Watch, Vue } from 'vue-property-decorator';
import {
    commitAddNotification,
    commitRemoveNotification,
} from '@/store/main/mutations';


@Component
export default class KrusSisyph extends Vue {

  get authheader() {
    return {
      headers: {
        Authorization : `Bearer ${this.$store.getters.token}`,
      },
  };
  }

  // table
  public csv_data: any = null;
  public pagination: any = {sortBy: 'id', descending: true, rowsPerPage: 10};
  public search: string = '';
  public tableHeaders: any =  [
          {
            text: 'id',
            align: 'hidden-xs-only',
            filterable: false,
            value: 'id',
          },
          { text: '', filterable: false, value: 'done' },
          { text: 'Имя', value: 'filename' },
          { text: 'описание', value: 'description' },
          { text: 'Строчек', value: 'datalen' },
        ];
  public tableData: any = [];
  // edit dialog window
  private dialog: boolean = true;
  private currentName: string = '';
  private currentDescr: string = '';
  private currentitem: any = null;

  public mounted() {
    this.getTableData();
    // setInterval(() => this.getTableData(), 10000);
  }

  private theFieldIsRequired(v: string) {
    if (v && v.length > 0) {
      return true;
    }
    return 'Это обязательное поле';
  }

  private loggedIn() {
    return this.$store.getters.isLoggedIn;
  }

  private openForm(item) {
    this.currentitem = item;
    this.dialog = true;
  }

  private submit() {
    this.dialog = false;
    this.editData(this.currentitem);
  }

  private editData(item) {
    const loadingNotification = { content: 'редактируем...', showProgress: true };
    commitAddNotification(this.$store, loadingNotification);
    axios.put(`${apiUrl}/api/v1/devices/sisyphus/krusfile/${item.id}`, item, this.authheader)
    .then((response) => {
      commitRemoveNotification(this.$store, loadingNotification);
      commitAddNotification(this.$store, { content: 'готово!', color: 'success' });
      this.getTableData();
      })
     .catch((error) => {
      let msg = 'Не удалось отредактировать! ';
      this.getTableData();
      if ('response' in error) {
        console.log(error.response);
        msg = msg + 'ошибка ' + error.response.status;
      }
      console.log(error);
      commitRemoveNotification(this.$store, loadingNotification);
      commitAddNotification(this.$store, { content: msg, color: 'error' });

     });

  }

  private removeData(id) {
    if (!confirm('Хотите удалить эту выгрузку?')) { return; }

    const loadingNotification = { content: 'Удаляем данные...', showProgress: true };
    commitAddNotification(this.$store, loadingNotification);
    axios.delete(`${apiUrl}/api/v1/devices/sisyphus/krusfile/${id}`, this.authheader)
    .then((response) => {
      commitRemoveNotification(this.$store, loadingNotification);
      commitAddNotification(this.$store, { content: 'данные удалены!', color: 'success' });
      this.getTableData();
      })
     .catch((error) => {
      let msg = 'Не удалось удалить файл! ';
      this.getTableData();
      if ('response' in error) {
        console.log(error.response);
        msg = msg + 'ошибка ' + error.response.status;
      }
      console.log(error);
      commitRemoveNotification(this.$store, loadingNotification);
      commitAddNotification(this.$store, { content: msg, color: 'error' });

     });
  }

  private getTableData() {
    if (!this.$store.getters.isLoggedIn) { return; }
    // get data from db
    axios.get(`${apiUrl}/api/v1/devices/sisyphus/krusfile/all`, this.authheader)
    .then((response) => {
      // console.log("recieved krusfiles");
      this.tableData = response.data;
      })
     .catch((error) => {
      let msg = 'Не удалось выгрузить данные! ';
      // this.bsFormState = 'error';
      if ('response' in error) {
        console.log(error.response);
        msg = msg + 'ошибка ' + error.response.status;
      }
      commitAddNotification(this.$store, { content: msg, color: 'error' });
     });
  }


  private sendFile(file) {
    console.log('file: ', file);
    const formData = new FormData();

    formData.append('file', file.target.files[0]);
    formData.set('name', file.target.files[0].name);
    formData.set('description', '-');

    const loadingNotification = { content: 'Отправляем файл...', showProgress: true };
    commitAddNotification(this.$store, loadingNotification);

    axios.post(`${apiUrl}/api/v1/devices/sisyphus/krusfile/`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization' : `Bearer ${this.$store.getters.token}`,
            },
    })
    .then((response) => {
      this.getTableData();
      let msg = response.data.msg;
      if (msg == null) { msg = 'файл будет обработан в течение минуты'; }
      commitRemoveNotification(this.$store, loadingNotification);
      commitAddNotification(this.$store, { content: msg, color: 'success' });
      })
     .catch((error) => {
      console.log('sendFile error: ', error);
      let msg = 'Не удалось загрузить файл';
      if ('response' in error && error.response) {
        msg = msg + 'ошибка ' + error.response.status;
      } else {
        msg = msg + ' ' + error;
      }
      commitRemoveNotification(this.$store, loadingNotification);
      commitAddNotification(this.$store, { content: msg, color: 'error' });

      console.log(error);
     });
  }

}
