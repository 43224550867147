


















































































































































































import { Component, Watch, Vue } from 'vue-property-decorator';
import { api } from '@/api';
import axios from 'axios';
import ip from 'ip';
import { apiUrl } from '@/env';
import { ValidateIPaddress, ValidateCIDRaddress } from '@/tools';
import {
    commitAddNotification,
    commitRemoveNotification,
} from '@/store/main/mutations';


// @Component({
//     components: {
//         "progress-bar" : ProgressBar,
//     }
// })
@Component
export default class FormUbiquiti extends Vue {


  get authheader() {
    return {
      headers: {
        Authorization : `Bearer ${this.$store.getters.token}`,
      },
  };
  }
  // form data
  private MaxCPE: number = 30;
  private chosenDevice: string = 'Ubiquiti';
  // private authheader: object = null;
  private bsip: string = '';
  private cpe_ip: string = ''; // temp for tests
  private CPE_LIST: string[] = [];
  private new_ip_cidr: string = '';
  private ip_first: string = '';
  private ip_last: string = '';
  private ntp: string = '';
  private snmp: string = '';
  private vlan: string = '';
  private login: string = '';
  private pwd: string = '';
  private dns1: string = '';
  private dns2: string = '';

  private billingData: any;

  private formState: string = 'info';
  private formMainMessage: string = 'производится подключение и настройка...';
  private formSubmitted: boolean = false;
  private switchTest: boolean = false;
  private switchCleanup: boolean = true;
  private ipRange: boolean = true;
  private loading: boolean = false;
  private gwIsUp: boolean = false; // if gw ip (CIDR) pings

  private dropdownloading: boolean = false;
  private dropdownitems: string[] = [];
  private dropdownsearch: any = null;
  private dropdownselect: string = '';
  private krusData: any = [];

  private IpCidrErr: string = '';
  private NoIpListErr: string = '';
  private ipRangeErrors: string = '';
  private filterByBSipErrors: string = '';

  // forms
  private form: any = null;

  // private submitted: boolean = false;
  // private submitMsg: string = 'отправлено';

  // buttons
  // clearIpRange

  @Watch('new_ip_cidr')
  public SelectIpCidrChanged(newVal: string) {
    this.clearIpRange();
    if (this.ipRange) {
      this.setIpRange();
    }
  }

  @Watch('dropdownselect')
  public SelectFileChanged(newVal: string) {
    this.NoIpListErr = '';
    const id = this.getFileId(newVal);
    if (id === 0) {
        console.log(`[SelectFileChanged] file id not found for ${newVal}`);
        return;
      }
    this.getCPElist(id);
    }

  @Watch('bsip')
  public BSFilterChanged(newVal: string) {
    this.NoIpListErr = '';
    if (newVal === '') {
      this.filterByBSipErrors = '';
    }
    const id = this.getFileId(this.dropdownselect);
    if (id === 0) {
        console.log(`[SelectFileChanged] file id not found for ${this.dropdownselect}`);
        return;
      }
    this.getCPElist(id);
    }


  public mounted() {
    this.form = this.$refs.bsform;
    this.getKrusData();
  }

  // form checkers
  private theFieldIsVlan(v: number) {
    if (v && v >= 0 && v < 4096) {
      return true;
    }
    return 'Должен быть номер от 0-4095';
  }

  private theFieldIsRequired(v: string) {
    if (v && v.length > 0) {
      return true;
    }
    return 'Это обязательное поле';
  }

  // fields rules
  private theFieldIsIp(v: string) {
    if (ValidateIPaddress(v)) {
      return true;
    }
    return 'Неверный формат ip адреса';
  }

 private theFieldIsIpOrEmpty(v: string) {
    if (v === '' || v === undefined) {
      return true;
    }
    if (ValidateIPaddress(v)) {
      return true;
    }
    return 'Неверный формат ip адреса';
  }

  // private TheCidrPings(v: string) {
  //   if (this.gwIsUp) {
  //     this.IpCidrErr = '';
  //     return true;
  //   }
  //   if (this.IpCidrErr === '') {
  //     return 'проверка на доступность...'
  //   }
  //   return this.IpCidrErr
  // }

  private PingCIDRip(v: string) {
    this.gwIsUp = false;
    this.IpCidrErr = '';
    if (!ValidateCIDRaddress(v)) {
      this.IpCidrErr = 'Неверный формат! попробуйте так: x.x.x.x/xx';
      return;
    }
    const gwip: string[] = v.split('/');

    axios.put(`${apiUrl}/api/v1/devices/ping/${gwip[0]}`, this.authheader)
    .then((response) => {
      if (response.data.ping) {
        this.gwIsUp = true;
        this.IpCidrErr = '';
      } else {
        this.gwIsUp = false;
        this.IpCidrErr = response.data.ip + ': не пингуется с сервера!';
      }
     })
     .catch((error) => {
      console.log('ping error: ', error);
      this.IpCidrErr = 'Не могу пингануть. Ошибка:' + error;
     });
  }

  private handleBillingFile(convertedData: any) {
    // console.log("input length: ",convertedData.body.length)
    // this.billingData = convertedData.body;
    const result = new Array();
    // let iplist = new Set();
    let i = 0;
    for (const row of convertedData.body) {
      i++;
      console.log('row: ', i);
      if (row.TYPE_NAME.toLowerCase().includes(this.chosenDevice.toLowerCase())) {
        if (row.IP_DEVICE) {
          result.push(row.IP_DEVICE);
        }

      }
      // this.CPE_LIST = [...new Set(iplist)]
    // this.CPE_LIST = iplist;
    }
    console.log('result len: ', result.length);

    // this.buildCpeList('all')
  }

  private getKrusData() {
    // get data from db
    axios.get(`${apiUrl}/api/v1/devices/sisyphus/krusfile/all`, this.authheader)
    .then((response) => {
      this.krusData = response.data;
      this.dropdownitems = [];
      for (const i of this.krusData) {
        // console.log(i.filename)
        this.dropdownitems.push(i.filename);
      }
      // this.getCPElist()
      })
     .catch((error) => {
      let msg = 'Не удалось вытащить КРУС-выгрузки! ';
      // this.bsFormState = 'error';
      if ('response' in error) {
        console.log(error.response);
        msg = msg + 'ошибка ' + error.response.status;
      }
      commitAddNotification(this.$store, { content: msg, color: 'error' });
     });
  }

  private getFileId(filename: string): number {

    for (const i of this.krusData) {
      if (i.filename === filename) {
        return i.id;
      }
    }
    return 0;
  }


  private getCPElist(id: number) {
    this.CPE_LIST = [];
    const data = {
      file_id : id,
      IP_PARENT : this.bsip,
      devicetype : this.chosenDevice,
    };
    // get data from db
    axios.post(`${apiUrl}/api/v1/devices/sisyphus/krusdata/cpelist/`, data , this.authheader)
    .then((response) => {
      this.CPE_LIST = response.data;
      })
     .catch((error) => {
      let msg = 'Не удалось выгрузить ip CPE! ';
      // this.bsFormState = 'error';
      if ('response' in error) {
        console.log(error.response);
        msg = msg + 'ошибка ' + error.response.status;
      }
      commitAddNotification(this.$store, { content: msg, color: 'error' });
     });
  }




  private submit() {
    if (this.CPE_LIST.length === 0) {
      this.NoIpListErr = 'Не найдены CPE для данного вендора!';
      return;
    }

    if (!this.bsip && this.CPE_LIST.length >= this.MaxCPE) {
      this.NoIpListErr = `Максимум без фильтрации ${this.MaxCPE}! Укажите ip сектора`;
      return;
    }

    if (this.form.validate()) {
      if (this.formSubmitted) {
          this.formState = 'info';
          this.formMainMessage = 'Задание уже отправлено!';
          return;
      }
      this.formState = 'info';
      this.formMainMessage = 'добавление задачи в очередь...';
      this.formSubmitted = true;
      this.sendTask();
    }
  }

  private resetForm() {
    this.formState = 'info';
    this.CPE_LIST = [];
    this.form.reset();
    this.formSubmitted = false;
  }

  // set default values if extra form opens
  private setIpRange() {
    this.ipRangeErrors = '';
    this.ip_first = ip.cidrSubnet(this.new_ip_cidr).firstAddress;
    this.ip_last = ip.cidrSubnet(this.new_ip_cidr).lastAddress;
    this.ipRange = true;
  }

  private clearIpRange() {
    this.ipRangeErrors = '';
    this.ip_first = '';
    this.ip_last = '';
  }

  private closeIpRange() {
    this.ipRange = false;
    this.clearIpRange();
  }

  // check if ip is part of network
  private ipRangeCheck(ip_value: string) {
    this.ipRangeErrors = '';
    if (!this.new_ip_cidr) {
      this.ipRangeErrors = 'заполните поле gw ip';
      return;
    }
    if (!ip.cidrSubnet(this.new_ip_cidr).contains(ip_value)) {
      this.ipRangeErrors = `${ip_value} не входит в ${this.new_ip_cidr}`;
      return;
    }
    if (this.ip_first && this.ip_last) {
      if (ip.toLong(this.ip_first) > ip.toLong(this.ip_last)) {
        this.ipRangeErrors = 'ip слева должен быть меньше ip справа';
        return;
      }
    }
  }

  private sendTask() { // send task to queue
    const data = {
      name: 'sisyphus',
      cleanup : this.switchCleanup,
      test : this.switchTest, // if true do not save and reboot devices
      krus : !this.switchTest, // do (not) save changes to krus database
      task : 'sisyphus',
      devicetype : this.chosenDevice,
      vlan : this.vlan,
      login : this.login,
      pwd : this.pwd,
      dns1: this.dns1,
      dns2: this.dns2,
      ntp : this.ntp,
      snmp: this.snmp,
      newgw_cidr : this.new_ip_cidr,
      iplist : this.CPE_LIST,
      ip_first: this.ip_first,
      ip_last: this.ip_last,
      // iplist: [this.cpe_ip],
    };
    axios.post(`${apiUrl}/api/v1/devices/jobs/multi/ip_change`, data, this.authheader)
    .then((response) => {
      // console.log(response);
      if ('data' in response) {
        this.formState = 'success';
        this.formMainMessage = response.data.msg;
        return;
        }
      this.formMainMessage = 'Сообщение не получено. Похоже что-то пошло не так :(';
      this.formState = 'warning';
      })
     .catch((error) => {
      this.formState = 'error';
      if ('response' in error) {
        // this.formSubmitted = false;
        const r = error.response;
        if ('data' in r) {
            this.formMainMessage = r.data.detail;
            return;
         }
        this.formMainMessage = ` Ошибка ${r.status}: ${r.statusText}`;
        return;
      }
      this.formMainMessage = ' Ошибка:' + error;
     });

  }

}
