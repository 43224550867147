




































































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TableSisyph extends Vue {

  // @Prop({default: false}) public dialog: boolean; // show this dialog
  @Prop({default: false}) public dialog!: boolean;
  @Prop({default: null}) public tabledata: any;

  public pagination: any = {sortBy: 'id', descending: true, rowsPerPage: 10};
  public search: string = '';
  public tableHeaders: any =  [
          {
            text: 'id',
            align: 'hidden-xs-only',
            filterable: true,
            value: 'id',
          },
          { text: 'Ок', filterable: false, value: 'success' },
          { text: 'КРУС', filterable: false, value: 'krus' },
          { text: 'старый ip', value: 'old_ip' },
          { text: 'новый ip', value: 'new_ip' },
          { text: 'сообщение', value: 'message' },
          // {
          //   text: 'job_id',
          //   align: 'hidden-xs-only',
          //   filterable: false,
          //   value: 'job_id',
          // },
          // { text: '', filterable: false, value: 'done' },
          // { text: 'Тип', value: 'devicetype' },
          // { text: 'ip шлюза', value: 'newgw_cidr' },
          // { text: 'Статус', value: 'status' },
        ];


  public close() {
    // this.dialog = false;
    this.$emit('close');
  }

  public dateConverter(s) {
    const d = new Date(s + 'Z');
    return d.toString();
  }
}


  // export default {
  //   data () {
  //     return {
  //       dialog: false,
  //       notifications: false,
  //       sound: true,
  //       widgets: false,
  //     }
  //   },
  // }
