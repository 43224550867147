




































import { Component, Watch, Vue } from 'vue-property-decorator';


import ToolPwdChange from '@/views/tools/pwd_change.vue';
import ToolSisyph from '@/views/tools/sisyph.vue';
import KrusSisyph from '@/views/tools/sisyph_krus.vue';


// @Component

@Component({
    components: {
        ToolPwdChange,ToolSisyph, KrusSisyph,
    },
})
export default class SisyphTabs extends Vue {
  private tab: any = null;
  private items: string[] = ['смена паролей','смена ip', 'Устройства'];
}
