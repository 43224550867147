















































































import { Component, Watch, Vue } from 'vue-property-decorator';
import { api } from '@/api';
import ip from 'ip';
import axios from 'axios';
import { apiUrl } from '@/env';
import { ValidateIPaddress, ValidateCIDRaddress } from '@/tools';


@Component
export default class FormInfinet extends Vue {
  // form data
  private chosenDevice: string = 'Infinet';
  // private authheader: object = null;
  private CPE_LIST: string[] = [];
  private new_ip_cidr: string = '';
  private ip_first: string = '';
  private ip_last: string = '';
  private ntp: string = '';
  private snmp: string = '';
  private vlan: number = 94;
  private bsip: string = '';
  private bsipErrors: string = '';
  private bsipOk: boolean = false;
  private bsIsConfiguring: boolean = false;
  private ipRange: boolean = true;

  private formState: string = 'info';
  private formMainMessage: string = 'производится подключение и настройка...';
  private formSubmitted: boolean = false;

  private bsLogin: string = '';
  private bsPassword: string = '';

  private submitted: boolean = false;
  private doNotchangeIpInKrus: boolean = false;
  private submitMsg: string = 'отправлено';


  private ipRangeErrors: string = '';
  // buttons
  private showReset: boolean = false;

  // forms
  private form: any = null;

  @Watch('new_ip_cidr')
  public SelectIpCidrChanged(newVal: string) {
    this.clearIpRange();
    if (this.ipRange) {
      this.setIpRange();
    }
  }

  public mounted() {
    this.form = this.$refs.bsform;
  }

  // form checkers
  private theFieldIsVlan(v: number) {
    if (v && v >= 0 && v < 4096) {
      return true;
    }
    return 'Должен быть номер от 0-4095';
  }

  private theFieldIsRequired(v: string) {
    if (v && v.length > 0) {
      return true;
    }
    return 'Это обязательное поле';
  }

  // fields rules
  private theFieldIsIp(v: string) {
    if (ValidateIPaddress(v)) {
      return true;
    }
    return 'Неверный формат ip адреса';
  }

  private theFieldIsCIDR(v: string) {
    if (ValidateCIDRaddress(v)) {
      return true;
    }
    return 'Неверный формат! попробуйте так: x.x.x.x/xx';
  }

  private checkIP() {
    // console.log('token ', this.$store.getters.token);
    // this.bsipOk = this.ValidateIPaddress(this.bsip);
    this.bsipErrors = '';
    if (!ValidateIPaddress(this.bsip)) {
      this.bsipErrors = 'Формат ip адреса: x.x.x.x';
      return;
    }

    axios.put(`${apiUrl}/api/v1/devices/ping/${this.bsip}`, this.authheader)
    .then((response) => {
      // console.log(response);
      if (!response.data.ping) {
        this.bsipErrors = response.data.ip + ': адрес не пингуется с сервера!';
        this.bsipOk = false;
      } else {
        this.bsipOk = true;
      }
     })
     .catch((error) => {
      this.bsipErrors = 'Не могу пингануть. Ошибка:' + error;
      console.log(error);
      this.bsipOk = false;
     });
  }

  private submit() {
    if (this.form.validate() && this.bsipOk) {
      if (this.formSubmitted) {
          this.formState = 'info';
          this.formMainMessage = 'Задание уже отправлено!';
          return;
      }
      this.formState = 'info';
      this.formMainMessage = 'производится подключение и настройка...';
      this.formSubmitted = true;
      this.submitToBs();
    }
  }

  private reset_forms() {
    this.formState = 'info';
    this.CPE_LIST = [];
    this.form.reset();
    this.formSubmitted = false;
    this.showReset = false;
  }


  // set default values if extra form opens
  private setIpRange() {
    this.ipRangeErrors = '';
    this.ip_first = ip.cidrSubnet(this.new_ip_cidr).firstAddress;
    this.ip_last = ip.cidrSubnet(this.new_ip_cidr).lastAddress;
    this.ipRange = true;
  }

  private clearIpRange() {
    this.ipRangeErrors = '';
    this.ip_first = '';
    this.ip_last = '';
  }

  private closeIpRange() {
    this.ipRange = false;
    this.clearIpRange();
  }

  // check if ip is part of network
  private ipRangeCheck(ip_value: string) {
    this.ipRangeErrors = '';
    if (!this.new_ip_cidr) {
      this.ipRangeErrors = 'заполните поле gw ip';
      return;
    }
    if (!ip.cidrSubnet(this.new_ip_cidr).contains(ip_value)) {
      this.ipRangeErrors = `${ip_value} не входит в ${this.new_ip_cidr}`;
      return;
    }
    if (this.ip_first && this.ip_last) {
      if (ip.toLong(this.ip_first) > ip.toLong(this.ip_last)) {
        this.ipRangeErrors = 'ip слева должен быть меньше ip справа';
        return;
      }
    }
  }

  private sendTask(bsip,bslogin,bspwd) { // send task to queue
    const data = {
      task : 'sisyphus',
      name: 'sisyphus',
      cleanup : true, // here it sends config save command using new ip via bs.
      krus : !this.doNotchangeIpInKrus, // save to krus database
      vlan : this.vlan,
      bsip : bsip,
      bslogin : bslogin,
      bspwd : bspwd,
      devicetype : this.chosenDevice,
      ntp : this.ntp,
      snmp: this.snmp,
      newgw_cidr : this.new_ip_cidr,
      iplist : this.CPE_LIST,
      ip_first: this.ip_first,
      ip_last: this.ip_last,
    };
    axios.post(`${apiUrl}/api/v1/devices/jobs/multi/ip_change`, data, this.authheader)
    .then((response) => {
      this.showReset = true;
      console.log(response);
      if ('data' in response) {
        this.formState = 'success';
        this.formMainMessage = response.data.msg;
        return;
        }
      this.formMainMessage = 'Сообщение не получено. Похоже что-то пошло не так :(';
      this.formState = 'warning';
      })
     .catch((error) => {
      this.showReset = true;
      this.formState = 'error';
      if ('response' in error) {
        // this.formSubmitted = false;
        const r = error.response;
        if ('data' in r) {
            this.formMainMessage = r.data.detail;
            return;
         }
        this.formMainMessage = `Ошибка ${r.status}: ${r.statusText}`;
        return;
      }
      this.formMainMessage = 'Ошибка:' + error;
     });

  }

  private submitToBs() { // get ip-list for all cpe of the bs
    const data = {
      ntp : this.ntp,
      snmp: this.snmp,
      device : this.chosenDevice,
      ip : this.bsip,
      // radio_iface : 'rf5.0',
      new_ip_cidr : this.new_ip_cidr,
      login : this.bsLogin,
      password : this.bsPassword,
    };
    console.log('data:', data);

    axios.post(`${apiUrl}/api/v1/devices/sisyphus/bs/all_cpe`, data, this.authheader)
    .then((response) => {
      // this.formSubmitted = false;
      console.log(response);
      if ('data' in response) {
        const len = response.data.cpe_list.length;
        const login = response.data.login;
        const password = response.data.password;
        this.CPE_LIST = response.data.cpe_list;
        if (len > 0) {
          this.formState = 'success';
          this.formMainMessage = 'количество CPE: ' + len;
          this.sendTask(this.bsip,login,password);
          return;
        }
        this.formMainMessage = 'Похоже к этому сектору не подключено ни одно устройство';
        this.formState = 'warning';
      }
     })
     .catch((error) => {
      this.showReset = true;
      // this.formSubmitted = false;
      this.formState = 'error';
      if ('response' in error) {
        // this.formSubmitted = false;
        const r = error.response;
        if ('data' in r) {
            this.formMainMessage = r.data.detail;
            return;
         }
        this.formMainMessage = `Ошибка ${r.status}: ${r.statusText}`;
        return;
      }
      this.formMainMessage = 'Ошибка:' + error;
     });
    }

  get authheader() {
    return {
      headers: {
        Authorization : `Bearer ${this.$store.getters.token}`,
      },
  };
  }

}
